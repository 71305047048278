import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import Label from "./label";
import Flex from "../containers/flex";
import Box from "../containers/box";
import { formatterNumber } from "../../utils/helperFunctions";

const Root = styled(Box)`
  width: 100%;

  input {
    margin-top: 15px;
    -webkit-appearance: none;
    width: 100%;
    height: 14px;
    background: rgba(48, 184, 66, 0.2);
    outline: none;
    border-radius: ${p => p.theme.borderRadiusRndBtn};

    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  input:hover {
    opacity: 1;
  }

  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: ${p => p.theme.greenDark};
    border: 14px solid ${p => p.theme.white};
    border-radius: 50%;
    cursor: pointer;
  }

  label {
    color: ${p => p.theme.blueDark};
    font-weight: ${p => p.theme.headingFont};
    margin-bottom: 0;

    @media (min-width: ${p => p.theme.screenS}) {
      margin-bottom: 12px;
    }
  }
`;

const InputWrap = styled(Box)`
  input {
    margin-top: 0;
    height: auto;
    font-weight: normal;
    background: ${p => p.theme.white};
    padding 11px 20px;
    border-radius: ${p => p.theme.borderRadiusRndBtn};
    font-size: ${p => p.theme.baseFontSizeTabMob};
    color: ${p => p.theme.greyDark};
    min-width: 104px;
    max-width: 106px;
    text-align: center;
    border: 4px solid #c6e1c9;

    @media (min-width: ${p => p.theme.screenL}) {
      font-size: ${p => p.theme.baseFontSizeDesktop};
      }
    }

    input:valid {
      color: ${p => p.theme.greyDark};
    }
    input:invalid {
      color: red;
    }

    input:focus::placeholder{
      color:transparent;
    }
 `;

const SliderTemplate = ({
  name,
  label,
  rangeMin,
  rangeMax,
  value,
  onChange,
  onBlur,
  error,
  touched,
  children,
  theme,
  ...rest
}) => {
  const minFormatted = formatterNumber(rangeMin);
  const maxFormatted = formatterNumber(rangeMax);

  return (
    <Root {...rest}>
      {/* Slider label and info */}
      <Flex
        justifyContent="space-between"
        alignItems={[`flex-start`, `center`]}
        flexDirection={[`column`, `row`]}
      >
        <Label
          htmlFor={name}
          error={error}
          touched={touched}
          color={theme.blueDark}
        >
          {error && touched ? error : label}
        </Label>

        {/* Slider value */}
        <InputWrap my={[12, 0]} ml={[0, 12]}>
          <input
            id={name}
            name={name}
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            maxLength="5"
            pattern="^[0-9]{2,}$"
          />
        </InputWrap>
      </Flex>

      {/* Slider */}
      {children}

      {/* Slider min/max values */}
      <Flex
        mt={[`24px`, null, `30px`]}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box color={theme.greyDark}>{minFormatted}</Box>
        <Box color={theme.greyDark}>{maxFormatted}</Box>
      </Flex>
    </Root>
  );
};

SliderTemplate.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  rangeMin: PropTypes.string,
  rangeMax: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  // value: PropTypes.number,
  required: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  theme: PropTypes.object.isRequired,
};

export default withTheme(SliderTemplate);
