import React from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"

import Box from "../containers/box"
import Container from "../containers/container"
import Heading from "../typography/heading"
import Text from "../typography/text"
import Button from "../buttons/button"
import Link from "../links/link"
import LinkButton from "../links/linkButton"

const Root = styled(Box)`
  text-align: center;
  position: relative;
  z-index: 10;

  > div:first-of-type {
    padding: 40px 24px;
    border-radius: ${p => p.theme.borderRadiusSqBtn};
    background: ${p => p.theme.grey};
  }

  > div > a:last-of-type {
    display: block;
    margin-top: 30px;
    padding: 0;
  }

  @media (min-width: ${p => p.theme.screenM}) {
    > div:first-of-type {
      padding: 50px 60px 45px 60px;
    }
  }
`

const CalculatorFormResponse = ({ setFormFired, error, theme }) => {
  const failedFormResponse = (
    <Container pt={[0, null, 70]} textAlign="center">
      <Heading as="h4" color={theme.red} mx="auto" mb={[24, null, null, 32]}>
        Sending Failed
      </Heading>

      <Text maxWidth={474} mb={[30, null, null, 42]}>
        Sending the form has failed. <br />
        You can still get in touch through either our social media (links can be
        found in the footer) or by emailing us at,{` `}
        <Link
          as="a"
          href="mailto:help@toptekkers.club?subject=User getting in touch. Contact form didn't work."
          className="bold"
        >
          help@toptekkers.club
        </Link>
        .
      </Text>

      <LinkButton as="a" href="/">
        Back to the home page
      </LinkButton>

      <Button as="a" color={theme.greyDark} mx="auto" onClick={setFormFired}>
        Try again
      </Button>
    </Container>
  )

  const successfulFormResponse = (
    <Container textAlign="center">
      <Heading as="h4" color={theme.green} mx="auto" mb={[24, null, null, 32]}>
        Thank you
      </Heading>

      <Text maxWidth={474} mb={[30, null, null, 42]}>
        Thank you for contacting us, we’ll be in touch with a response as soon
        as possible.
      </Text>

      <LinkButton as="a" href="/">
        Back to the home page
      </LinkButton>

      <Button color={theme.greyDark} as="a" mx="auto" onClick={setFormFired}>
        Make another enquiry
      </Button>
    </Container>
  )

  return (
    <Root error={error}>
      {!error ? successfulFormResponse : failedFormResponse}
    </Root>
  )
}

CalculatorFormResponse.propTypes = {
  setFormFired: PropTypes.func.isRequired,
  error: PropTypes.bool,
  theme: PropTypes.object.isRequired,
}

export default withTheme(CalculatorFormResponse)
