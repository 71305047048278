import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"

import SEO from "../components/globals/seo"
import Layout from "../components/containers/layout"
import Section from "../components/containers/section"
import Banner from "../components/containers/banner"
import Container from "../components/containers/container"
import Heading from "../components/typography/heading"
import Text from "../components/typography/text"
import PageTitle from "../components/typography/pageTitle"
import FreeTrialBanner from "../components/banners/freeTrialBanner"
import CalculatorForm from "../components/forms/calculatorForm"
import CalculatorBanner from "../components/banners/calculatorBanner"
import FeatureList from "../components/globals/featureList"
import { paymentPlans } from "../utils/content/pricing"
import { createHubspotDeal } from "../actions/hubspotFormActions"
import { getRegionData, getCountryPrices } from "../actions/paymentActions"
import { calculateTailoredCost } from "../utils/helperFunctions"
import CalculatorFormResponse from "../components/forms/calculatorFormResponse"

const ContainerPositionRel = styled(Container)`
  max-width: 681px;

  * {
    position: relative;
    z-index: 10;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`
const FreeTrialBannerCalculator = styled(FreeTrialBanner)`
  display: none;

  @media (min-width: ${p => p.theme.screenM}) {
    display: block;
  }
`

const ClubCalculatorPage = ({ theme }) => {
  // State: submit tracking + loading
  const [formFired, setFormFired] = useState(``)
  const [isLoading, setIsLoading] = useState(false)
  // State: currency symbol and code
  const [countryCode, setCountryCode] = useState(`GB`)
  const [currencyCode, setCurrencyCode] = useState(`GBP`)

  // Set response component
  let responseComponent
  if (formFired === `success`) {
    responseComponent = (
      <CalculatorFormResponse setFormFired={() => setFormFired(``)} />
    )
  } else if (formFired === `error`) {
    responseComponent = (
      <CalculatorFormResponse setFormFired={() => setFormFired(``)} error />
    )
  }

  const handleCalculatorFormSubmit = values => {
    // 1. Add price and currency code into values collected from form
    values = {
      ...values,
      priceQuoted: calculateTailoredCost(values.licenceCount),
      currencyCode,
    }
    // 2. Set loading and call API function with values
    setIsLoading(true)
    console.log(`sent`)

    createHubspotDeal(values)
      .then(() => {
        setFormFired(`success`)
        setIsLoading(false)
        window.scrollTo(0, 0)
      })
      .catch(() => {
        setFormFired(`error`)
        setIsLoading(false)
        window.scrollTo(0, 0)
      })
  }

  // 1. useEffect (componentDidMount)
  useEffect(() => {
    // 2. Fire API to get countryCode ready to feed to formatterPrice()
    getRegionData().then(res => {
      // 3. USe countryCode to find currencyCode
      getCountryPrices(res.countryCode).then(result => {
        // 4. Set countryCode/currencyCOde state
        setCountryCode(res.countryCode)
        setCurrencyCode(result.data[0].currency)
      })
    })
    // * Passing empty array to prevent being called on every re-render
  }, [])

  return (
    <Layout navbarPadOnly>
      <SEO title="Club Calculator" />

      {formFired === `` ? (
        <CalculatorBanner bg={theme.yellow} height={[400, null, 680, 784]}>
          <ContainerPositionRel pt={[65, null, 85]}>
            <PageTitle color={theme.white}>CLUBS</PageTitle>
            <Heading color={theme.white}>
              Tailor your cost for your club or organisation
            </Heading>
            <Text
              fontSize={[`18px`, null, null, `24px`]}
              color={theme.white}
              maxWidth={474}
            >
              Use our calculator to add licenses and see how much it could cost.
            </Text>
          </ContainerPositionRel>
        </CalculatorBanner>
      ) : (
        <CalculatorBanner py="40px" bg={theme.yellow}>
          <ContainerPositionRel>{responseComponent}</ContainerPositionRel>
        </CalculatorBanner>
      )}

      {formFired === `` && (
        <>
          <Section bg="transparent">
            <Container px="12px">
              <CalculatorForm
                onSubmit={handleCalculatorFormSubmit}
                calculateTailoredCost={calculateTailoredCost}
                countryCode={countryCode}
                currencyCode={currencyCode}
                loading={isLoading}
              />
            </Container>
          </Section>

          <Section className="hideOnMobile" id="features">
            <Container textAlign={[null, `center`]}>
              <Heading
                as="h3"
                mb={[20, null, 50]}
                mx="auto"
                textAlign={[null, `center`]}
              >
                Club Features
              </Heading>

              <FeatureList features={paymentPlans[2].features} />
            </Container>
          </Section>

          <FreeTrialBannerCalculator />
        </>
      )}
    </Layout>
  )
}

ClubCalculatorPage.propTypes = {
  theme: PropTypes.object.isRequired,
}

export default withTheme(ClubCalculatorPage)
