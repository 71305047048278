import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { withFormik } from "formik";
import * as Yup from "yup";

import Box from "../containers/box";
import Form from "./form";
import PageTitle from "../typography/pageTitle";
import SliderInput from "./sliderInput";
import Textarea from "./textareaInput";
import Text from "../typography/text";
import Input from "./input";
import Button from "../buttons/button";
import {
  formatterPrice,
  calculateTailoredCost,
} from "../../utils/helperFunctions";

const Root = styled(Box)`
  form {
    display: flex;
    flex-direction: column;
    margin-top: -70px;

    > div:first-of-type {
      width: 100%;
    }
    > div:last-of-type {
      width: 100%;
    }

    @media (min-width: ${p => p.theme.screenM}) {
      flex-direction: row;
      margin-top: -266px;

      > div:first-of-type {
        width: 65%;
      }
      > div:last-of-type {
        width: 35%;
      }
    }
  }
`;

const Calculator = styled(Box)`
  padding: 40px 24px;
  border-top-left-radius: ${p => p.theme.borderRadiusSqBtn};
  border-top-right-radius: ${p => p.theme.borderRadiusSqBtn};

  > div:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${p => p.theme.screenM}) {
    padding: 50px 60px 45px 60px;
    border-top-left-radius: ${p => p.theme.borderRadiusSqBtn};
    border-bottom-left-radius: ${p => p.theme.borderRadiusSqBtn};
  }
`;

const Results = styled(Box)`
  min-width: 280px;
  padding: 50px 40px;
  color: ${p => p.theme.white};
  text-align: center;

  border-bottom-left-radius: ${p => p.theme.borderRadiusSqBtn};
  border-bottom-right-radius: ${p => p.theme.borderRadiusSqBtn};

  @media (min-width: ${p => p.theme.screenM}) {
    border-top-right-radius: ${p => p.theme.borderRadiusSqBtn};
    border-bottom-right-radius: ${p => p.theme.borderRadiusSqBtn};
  }
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 40px 0 30px 0;
`;

const Price = styled(Box)`
  margin: 30px 0 5px;
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.largeFontSize};
`;

const ContactButton = styled(Button)`
  width: 100%;
  font-family: ${p => p.theme.headingFont};
`;

const CalculatorForm = ({
  countryCode,
  currencyCode,
  loading,
  theme,
  // Formik props
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
}) => {
  let count = values.licenceCount;
  if (count < 10) {
    count = NaN;
  }
  // Format and round up the cost to 2 decimal points
  const tailoredCostFormatted = formatterPrice(
    calculateTailoredCost(count),
    countryCode,
    currencyCode,
  );

  const handleSliderBlur = (e) => {
    if (e.target.value < 10) setFieldValue(`licenceCount`, 10);
  };

  return (
    <Root>
      <Form>
        <Calculator bg={theme.grey}>
          <PageTitle color={theme.blueDark} mb={[12, null, 24]}>
            LICENSES
          </PageTitle>

          <SliderInput
            name="licenceCount"
            label="How many player licenses are required?"
            value={values.licenceCount}
            errors={errors.licenceCount}
            touched={touched.licenceCount}
            onChange={handleChange}
            onBlur={handleSliderBlur}
            rangeMin="10"
            rangeMax="10000"
            step="10"
          >
            <Text fontSize={theme.smallFontSize} mt="0" mb="18px">
              There is a minimum of 10 licenses. Move the slider or type in the
              value manually.
            </Text>
          </SliderInput>

          <Textarea
            name="message"
            label="Please share as much information about your tailored quote with us as you can."
            color={theme.blueDark}
            placeholder="Enter anything you’d like to tell us here. Club name, coach count, the more you add the quicker it will be for us to help you with your enquiry."
            value={values.message}
            error={errors.message}
            touched={touched.message}
            onBlur={handleBlur}
            onChange={handleChange}
            minHeight="300px"
          />

          <PageTitle
            color={theme.blueDark}
            mt={[20, null, 40]}
            mb={[12, null, 24]}
          >
            CONTACT DETAILS
          </PageTitle>

          <Input
            name="firstName"
            label="Your first name"
            placeholder="Enter your first name"
            value={values.firstName}
            error={errors.firstName}
            touched={touched.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            color={theme.blueDark}
          />

          <Input
            name="lastName"
            label="Your last name"
            placeholder="Enter your last name"
            value={values.lastName}
            error={errors.lastName}
            touched={touched.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            color={theme.blueDark}
          />

          <Input
            name="email"
            label="Your email address"
            placeholder="Enter your email address"
            value={values.email}
            error={errors.email}
            touched={touched.email}
            onBlur={handleBlur}
            onChange={handleChange}
            color={theme.blueDark}
          />
        </Calculator>

        <Results bg={theme.blueDark}>
          <Box>
            <PageTitle color={theme.green} textAlign="center" mb="0">
              BILLED ANNUALLY
            </PageTitle>
            <Price value={values.tailoredCost} className="bold" color={theme.white}>
              {tailoredCostFormatted}
            </Price>
            <Box color={theme.white}>per month</Box>
          </Box>

          <Separator />

          <Box>
            <ContactButton type="sumbit" bg={theme.green} loading={loading}>
              Contact the sales team
            </ContactButton>
            <Text
              pt="30px"
              color={theme.greyLight}
              fontSize={theme.smallFontSize}
              lineHeight="24px"
            >
              We&apos;ve estimated your monthly cost based on the options
              you&apos;ve chosen above. Any applicable taxes are not included,
              and your fees are subject to increase with additional purchases.
            </Text>
          </Box>
        </Results>
      </Form>
    </Root>
  );
};

CalculatorForm.propTypes = {
  countryCode: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  // Formik
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

const formikCalculatorForm = withFormik({
  // Map incoming prop data from form inputs values
  mapPropsToValues: () => ({
    firstName: ``,
    lastName: ``,
    email: ``,
    message: ``,
    licenceCount: 1000,
  }),

  // Validate the form using Yup
  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .label(`First name`)
      .trim()
      .required(),
    lastName: Yup.string()
      .label(`Last name`)
      .trim()
      .required(),
    email: Yup.string()
      .label(`Email`)
      .email()
      .required(),
    message: Yup.string()
      .label(`Additional information`)
      .required(),
  }),
  // Submit and reset the form
  handleSubmit: (values, { resetForm, props }) => {
    props.onSubmit(values);
    resetForm();
  },
  // Display name for easy loaction in react dev tools
  displayName: `Calculator Form`,
})(CalculatorForm);

export default withTheme(formikCalculatorForm);
