import styled from "styled-components"

const Label = styled.label`
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.baseFontSizeDesktop};
  color: ${p => p.theme.white};

  ${p =>
    p.color &&
    `
    color: ${p.color};
  `}

  ${p =>
    p.touched &&
    p.error &&
    `
      color: ${p.theme.red};
    `}
`

export default Label
